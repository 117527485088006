import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"
import officeAddress from "../../../images/officeAddress.png"
import sendEmail from "../../../images/sendEmail.png"
import telephone from "../../../images/telephone.png"

export default class CompanyDetails extends Component {
  getBox(src, contentHeading, content) {
    var lineSplit = content.split("%")
    return (
      <div className="d-inline-block col-12 col-md-4">
        <div className="col-12 d-flex justify-content-center">
          <div className="col-9">
            <div className="d-flex justify-content-center">
              <img style={styles.image} src={src} alt="" />
            </div>
            <div style={styles.imageDescription}>{contentHeading}</div>
            <div style={styles.contentDescription}>
              {lineSplit.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return (
      <div style={{ paddingTop: 80 }}>
        {this.getBox(
          officeAddress,
          "Our Office",
          "101,102 Wings%Srinagar Colony%Hyderabad, India 500073"
        )}
        {this.getBox(sendEmail, "Email", "CT@RIKTAMTECH.COM")}
        {this.getBox(telephone, "Phone", "+91 40 666 88 305")}
      </div>
    )
  }
}

var styles = {
  image: {
    width: 60,
    height: 60,
  },
  imageDescription: {
    color: COLORS.CONTACT_DETAILS_HEADING,
    // fontSize: 15,
    fontWeight: 900,
    fontFamily: "SFProHvy",
    textAlign: "center",
    paddingTop: 50,
  },
  contentDescription: {
    color: COLORS.CONTACT_DETAILS,
    // fontSize: 14,
    fontWeight: "bold",
    fontFamily: "SFProSmBold",
    paddingTop: 10,
    paddingBottom: 120,
    textAlign: "center",
  },
}
