import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"
import TextComponent from "../FormRequirements/TextComponent"
import contactBG from "../../../images/contactBG.png"

import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import WarningIcon from "@material-ui/icons/Warning"
import ErrorIcon from "@material-ui/icons/Error"
import CustomSnackbar from "../FormRequirements/CustomSnackbar"
import { getUrl } from "../../../network/apis"

export default class Form extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      telephone: "",
      message: "",

      isSubmittingForm: false,
      isSnackbarOpen: false,
      snackbarIcon: "",
      snackbarMessage: "",
      snackbarColor: "",
    }
    this.submitContactForm = this.submitContactForm.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.isValidEmail = this.isValidEmail.bind(this)
  }

  isValidEmail() {
    var value = this.state.email
    var pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    if (!value.match(pattern)) {
      this.setState({
        isSnackbarOpen: true,
        snackbarColor: COLORS.SNACKBAR_WARNING,
        snackbarIcon: WarningIcon,
        snackbarMessage: "Please enter a valid email id",
      })
      document.getElementById("inputemail").focus()
      return false
    }
    return true
  }

  areMandatoryFieldsFilled() {
    var required = []
    if (!this.state.name) required.push("Name")
    if (!this.state.email) required.push("Email")
    if (!this.state.telephone) required.push("Telephone")
    if (required.length) {
      this.setState({
        isSnackbarOpen: true,
        snackbarIcon: WarningIcon,
        snackbarMessage:
          "Please fill the required field(s) - " + required.join(", "),
        snackbarColor: COLORS.SNACKBAR_WARNING,
      })
      return false
    } else if (!required.length && this.isValidEmail()) {
      return true
    }
    return false
  }

  closeSnackbar() {
    this.setState({
      isSnackbarOpen: false,
    })
  }

  submitContactForm() {
    var mandatoryFieldsFilled = this.areMandatoryFieldsFilled()
    if (mandatoryFieldsFilled) {
      this.setState({ isSubmittingForm: true })
      var body =
        "column_10738=" +
        this.state.name +
        "&column_10739=" +
        this.state.email +
        "&column_10740=" +
        this.state.telephone +
        "&column_10741=" +
        this.state.message +
        "&form_id=12137"
      fetch(getUrl("webform"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      })
        .then(response => {
          return response.json()
        })
        .then(res => {
          var snackbarMessage = "",
            snackbarColor = "",
            snackbarIcon = "",
            isSuccess = true
          if (res.success) {
            snackbarIcon = CheckCircleIcon
            snackbarMessage = "Submitted Successfully"
            snackbarColor = COLORS.SNACKBAR_SUCCESS
          } else {
            snackbarIcon = ErrorIcon
            snackbarMessage = "Submission Failed"
            snackbarColor = COLORS.SNACKBAR_FAIL
            isSuccess = false
          }
          this.setState({
            isSubmittingForm: false,
            isSnackbarOpen: true,
            snackbarIcon: snackbarIcon,
            snackbarMessage: snackbarMessage,
            snackbarColor: snackbarColor,
            name: isSuccess ? "" : this.state.name,
            email: isSuccess ? "" : this.state.email,
            telephone: isSuccess ? "" : this.state.telephone,
            message: isSuccess ? "" : this.state.message,
          })
        })
        .catch(err => {
          this.setState({
            isSubmittingForm: false,
            isSnackbarOpen: true,
            snackbarIcon: ErrorIcon,
            snackbarMessage: "Submission Failed. Please try again later.",
            snackbarColor: COLORS.SNACKBAR_FAIL,
          })
          console.log(err)
        })
    }
  }

  inputChanged(type, value) {
    if (type == "Name") this.setState({ name: value })
    if (type == "Email") this.setState({ email: value })
    if (type == "Telephone") this.setState({ telephone: value })
    if (type == "Message...") this.setState({ message: value })
  }

  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div className="col-12 col-md-6" style={styles.image}>
            <img
              className="img-fluid"
              style={{ height: 350 }}
              src={contactBG}
              alt=""
            />
          </div>
          <div className="col-12 col-md-6" style={styles.form}>
            <span className="d-inline-block col-sm-2" />
            <div
              style={{
                color: COLORS.THEME_COLOR,
                fontSize: 22,
                lineHeight: 1.2,
                fontFamily: "SFProHvy",
                // letterSpacing: 0.4,
              }}
              className="pl-1 d-inline-block col-sm-10 col-md-10"
            >
              <div className="pl-0 d-inline-block col-sm-10 col-md-8">
                SOUNDS LIKE WE CAN HELP YOU?
              </div>
            </div>
            <span className="d-inline-block col-sm-2" />
            <div
              style={{
                color: COLORS.THEME_COLOR,
                fontSize: 46,
                lineHeight: 1.2,
                // fontWeight: "bold",
                fontFamily: "SFProReg",
                // letterSpacing: -0.66,
                // letterSpacing: 0.66,
              }}
              className="pl-0 d-inline-block col-sm-10 col-md-8"
            >
              GET IN TOUCH
            </div>
            <span className="d-inline-block col-sm-2" />
            <div className="p-0 col-md-10" style={{ marginTop: 20 }}>
              <span className="d-inline-block col-md-2" />
              <div className="p-0 d-inline-block col-12 col-md-10">
                <TextComponent
                  placeHolder="Name"
                  type="text"
                  lineType="singleLine"
                  className="p-0 pr-lg-1 col-12 col-lg-6"
                  value={this.state.name}
                  inputChanged={this.inputChanged}
                />
                <TextComponent
                  placeHolder="Email"
                  type="email"
                  lineType="singleLine"
                  className="p-0 col-12 col-lg-6"
                  value={this.state.email}
                  inputChanged={this.inputChanged}
                />
                <TextComponent
                  placeHolder="Telephone"
                  type="number"
                  lineType="singleLine"
                  className="p-0 pr-lg-1 col-12 col-lg-6"
                  value={this.state.telephone}
                  inputChanged={this.inputChanged}
                />
              </div>
              <div className="p-0 col-12">
                <span className="d-inline-block col-md-2" />
                <div
                  className="p-0 d-inline-block col-12 col-md-10"
                  style={{ marginTop: 10 }}
                >
                  <TextComponent
                    placeHolder="Message..."
                    type="text"
                    lineType="multiLine"
                    style={{ height: 80 }}
                    className="p-0 col-12"
                    value={this.state.message}
                    inputChanged={this.inputChanged}
                  />
                  <div className="p-0 d-inline-block col-12">
                    {this.state.isSubmittingForm ? (
                      <button
                        className="col-12 col-lg-5"
                        style={styles.submitButton}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          style={{ marginRight: 10 }}
                        />
                        Sending...
                      </button>
                    ) : (
                      <button
                        className="col-12 col-lg-5 submitButton"
                        style={styles.submitButton}
                        onClick={this.submitContactForm}
                      >
                        Send Message
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomSnackbar
          isSnackbarOpen={this.state.isSnackbarOpen}
          closeSnackbar={this.closeSnackbar}
          snackbarColor={this.state.snackbarColor}
          snackbarIcon={this.state.snackbarIcon}
          snackbarMessage={this.state.snackbarMessage}
        />
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.SCREEN_BACKGROUND,
    paddingTop: 50,
    paddingBottom: 50,
  },
  subContainer: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  image: {
    position: "relative",
    float: "right",
  },
  form: {
    position: "relative",
    float: "left",
  },

  submitContainer: {},
  submitButton: {
    textDecoration: "none",
    fontFamily: "SFProSmBold",
    textAlign: "center",
    // backgroundColor: COLORS.THEME_COLOR,
    border: "none",
    color: COLORS.LIGHT_TEXT_COLOR,
    padding: 7,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 8,
  },
}
