import React, { Component } from "react"
import ContactCmp from "../components/Description/Contact/Contact"
import Layout from "../components/Layout"

export default function Contact() {
    return (
        <Layout>
            <ContactCmp />
        </Layout>
    )
}
