import React, { Component } from "react"
import Form from "./Form"
import CompanyDetails from "./CompanyDetails"

export default class Contact extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Form />
        <CompanyDetails />
      </div>
    )
  }
}
